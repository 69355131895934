import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { appRoutes } from './app.routing';
import { DomSanitizer } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { MlkIconRegistery } from '@shared/ui';
import {
  ErrorsInterceptor,
  HttpBackendInterceptor,
} from '@shared/interceptors';
import * as fr from '@angular/common/locales/fr';
import { ApiModule, Configuration, ConfigurationParameters } from 'gen';
import { environment } from 'src/environments/environment';
import { FEATURE_TOGGLING, featureToggling } from './common';
import { DialogModule } from '@angular/cdk/dialog';

registerLocaleData(fr.default);

export function apiConfiguration(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.basePath,
  };
  return new Configuration(params);
}

export function initializeIcon(
  iconRegistery: MlkIconRegistery,
  domSanitizer: DomSanitizer
) {
  return () => {
    const baseSvg = 'assets/icons';

    const icons = [
      { name: 'full-star', path: `${baseSvg}/full-star.svg` },
      { name: 'half-star', path: `${baseSvg}/half-star.svg` },
      { name: 'chevron-right', path: `${baseSvg}/chevron-right.svg` },
      { name: 'chevron-left', path: `${baseSvg}/chevron-left.svg` },
    ];

    icons.forEach(icon => {
      return iconRegistery.addSvgIcon(
        icon.name,
        domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([ApiModule.forRoot(apiConfiguration)], DialogModule),
    provideRouter(appRoutes, withComponentInputBinding()),
    // provideHttpClient(withInterceptors([authInterceptor])) methode without dependency injection
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: FEATURE_TOGGLING, useValue: featureToggling },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBackendInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeIcon,
      deps: [MlkIconRegistery, DomSanitizer],
      multi: true,
    },
  ],
};
