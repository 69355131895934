import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastsContainerComponent } from '@shared/components';
import { FooterComponent, MenuComponent } from '@shared/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    MenuComponent,
    ToastsContainerComponent,
    FooterComponent,
  ],
})
export class AppComponent {}
