import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { AuthenticationService } from '@shared/services';
import { LoginDialogComponent } from '@shared/components';
import { map } from 'rxjs';

export function authenticationGuardV1(): CanActivateFn {
  return () => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    if (authenticationService.hasValidAccessToken()) {
      return true;
    }
    return router.createUrlTree(['/login']);
  };
}

export function authenticationGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const dialog = inject(Dialog);
    if (authenticationService.hasValidAccessToken()) {
      return true;
    }

    const loginDialog = dialog.open<boolean, void, LoginDialogComponent>(
      LoginDialogComponent,
      { disableClose: true }
    );

    return loginDialog.closed.pipe(
      map(data => {
        if (data === true) {
          return true;
        }
        const firstChildPath = route.firstChild?.routeConfig?.path ?? '/';
        if (firstChildPath) {
          return router.createUrlTree([firstChildPath]);
        }
        return false;
      })
    );
  };
}
